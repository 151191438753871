.four04 {
    padding: 40px;
    margin-top: 115px;
    display: flex;
    justify-content: center;
    align-items: center;
    max-width: 1024px;
    margin: 0 auto;
    height: 100vh;

    h1 {
        font-size: 38px;
        font-weight: bold;
        margin-top: 0;
        margin-bottom: 20px;

        span{
            font-size: 48px;
            display: block;
        }
    }
}